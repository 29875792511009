import $api from "./index";
import {LogoutAction} from "store/auth/actions";
import {NotificationOpen} from "store/notification/actions";
import AuthService from "services/authService";
import keycloak from "Keycloak";
import {LogMap, LogMapWithParams} from "constants/logs";
import {LogsAddAction} from "store/logs/actions";

export const fetchersInterceptor = (store: any) => {
    $api.interceptors.request.use(async function (request) {
        try {
            await keycloak.updateToken(15);
            const token = keycloak.token;

            if (token) {
                request.headers.set('Authorization', `Bearer ${token}`)
            }
        } catch (error) {
            return Promise.reject(error);
        }

        return request;
    }, function (error) {
        return Promise.reject(error);
    });

    $api.interceptors.response.use(function (response) {
        if (response && response.status === 200) {
            AuthService.setToStorageTime();
        }
        const method= response.config?.method
        const url = response.config?.url
        let actionCode = response.config?.headers?.['Actioncode']

        if (method && url && isAccessAllowed(url)) {
            actionCode = !actionCode ? LogMap[method]?.[url] : actionCode // если в заголовках нет то берем из LogMap
            if (!actionCode) {
                const parts = url.split('/');
                parts.pop();
                const newUrl = parts.join('/');
                actionCode = LogMapWithParams[method]?.[newUrl]
            }
            store.dispatch(LogsAddAction({
                actionTypeCode: actionCode || 'UNCODED_ACTION',
                routingPageAddress: window?.location?.origin + window.location?.pathname,
                requestUrl: url,
                requestMethod: method,
                requestPayload: !response.config.data ? null : response.config.data instanceof FormData ?
                    formDataToObject(response.config.data) : response.config.data,
                methodStatusCode: String(response.status),
                requestResponse: response.data ? JSON.stringify(response.data) : null,
            }))
        }

        return response;
    }, async function (error) {
        const method= error.response.config?.method
        const requestUrl = error.response.config?.url
        let actionCode = error.response.config?.headers?.['Actioncode']

        if (method && requestUrl && isAccessAllowed(requestUrl)) {
            actionCode = !actionCode ? LogMap[method]?.[requestUrl] : actionCode
            if (!actionCode) {
                const parts = requestUrl.split('/');
                parts.pop();
                const newUrl = parts.join('/');
                actionCode = LogMapWithParams[method]?.[newUrl]
            }
            store.dispatch(LogsAddAction({
                actionTypeCode: actionCode || 'UNCODED_ACTION',
                routingPageAddress: window?.location?.origin + window.location?.pathname,
                requestUrl,
                requestMethod: method,
                requestPayload: !error.response.config.data ? null : error.response.config.data instanceof FormData ?
                    formDataToObject(error.response.config.data) : error.response.config.data,
                requestResponse: error.response.data ? JSON.stringify(error.response.data) : null,
                methodStatusCode: String(error.response.status),
            }))
        }

        const url = error.request.responseURL.split('/').at(-1);
        const isAuth = store.getState().auth.isLogin;
        if (401 === error.response.status && url !== 'current_user' && url !== 'actions' && isAuth) {
            try {
                store.dispatch(LogoutAction());
                store.dispatch(NotificationOpen(
                    "warning",
                    'Вы не авторизованы',
                    'Пожалуйста авторизуйтесь',
                    true
                ));
            } catch (_e: any) {}
        }
        return Promise.reject(error);
    });
};

function formDataToObject(formData: FormData): any {
    const obj: { [key: string]: FormDataEntryValue } = {};
    Array.from(formData.entries()).forEach(([key, value]) => {
        obj[key] = value;
    });
    return JSON.stringify(obj);
}
function isAccessAllowed(url: string | undefined): boolean {
    const blockedPaths: string[] = [
        '/users/actions',
        '/notifications/browser-notifications/current_user',
        '/users/actions/search'
    ];

    if (!url) {
        return false;
    }

    return !blockedPaths.includes(url);
}



